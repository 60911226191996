import * as RedirectionPath from '../../common/redirect';

import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Sort } from '@angular/material/sort';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-partnerBalance',
  templateUrl: './partnerBalance.component.html',
  styleUrls: ['./partnerBalance.component.scss'],
  providers: [DatePipe, NgxUiLoaderService]
})

export class PartnerBalanceComponent implements OnInit {
  public environment = environment;
  transactionStatus:any="";
  accountStatus:any="";
  fromdate: any = "";  todate: any = "";  fromdepdate: any = ""; todepdate:any = ""; searchreportbool:boolean=false; airlinecode: any = ""; paymentStatus:any="";totalpage: any;data1: any;
  NoOfStopData=[
    { id: 'cash', name: "CASH" },
    { id: 'cheque', name: "CHEQUE" },
    { id: 'rtgs', name: "RTGS" },
    { id: 'neft', name: "NEFT" },
    { id: 'imps', name: "IMPS" }
  ]
  transaction_type:any='';
  profileImg: any;   usertype:any='balance';
  return_d_date: any;
  rreturn_d_date: any;
  sellerDtl: boolean = false;
  sort_narration: any = "desc";
  sort_amount: any = "desc";
  sort_account_type: any = "desc";   public sentrequestform: FormGroup;
  date: any;
  @ViewChild("fileInput1", { static: false }) fileInput1: ElementRef; files = [];
  @ViewChild("inputSearch", { static: false }) inputSearch: ElementRef;
  ccData: any;  RData:any;  public imagePath;  image:any;  File: File=null;  public message: string='';
  returnFlight: any;
  newData: any;
  compDtl2: any;
  imgPath: any;
  bookData: any;
  flightData: any;
  creditCount:any
  compData: any;
  compDtl: any;
  sortedList: any[];
  old_credit_limit: any;
  bgBannerPath: any;
  detail_data: any;
  submitted: boolean = false;
  flt_bokg_dtl: any;
  searchFlagReset: boolean = false; bodydata: any = {};
  TotalCount: any; data: any; keys: any; public page: number = 1; start = 0; msg: any; status: any; FormId: any; flag: boolean = false;
  accountData: any;
  account: any;
  todaydate: any = this.datePipe.transform(new Date(), 'dd-MM-yyyy')
  public addaccountValue: FormGroup;
  element: any = {};
  userdata: any;
  acountPartner: any;
  old_available_balance: any;
  retrn: any;
  ticket = "Ticket Sold : ";
  p: number = 1;
  TotalCount1: any;
  reversData:any;
  booking_id: any;
  account_type: any;
  constructor(
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public router: Router,
    private datePipe: DatePipe,
    private toastr: ToastrManager,
    private activatedRoute: ActivatedRoute,
    public dataservice: CommonService,
    private ngxLoader: NgxUiLoaderService
  ) {

    this.userdata = JSON.parse(localStorage.getItem("user"));
    this.sortedList = this.ccData;

    if (sessionStorage.getItem("account_detail_data")) {
      this.detail_data = JSON.parse(sessionStorage.getItem("account_detail_data"))
    }
  }

  ngOnInit() {


    // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      this.addaccountValue = this.fb.group({
        share_inventorie_id: ['', Validators.required],
        share_to_user_id: ['', Validators.required],
        amount: ['', Validators.required],
        narration: ['', Validators.required],
      })
      if(this.detail_data){
        this.element = {
          share_inventorie_id: this.detail_data.share_inventorie_id,
          share_to_user_id: this.detail_data.share_to_user_id,
          share_by_user_id: this.detail_data.share_by_user_id,
          page_limit: "10",
          page_no: "1",
          search_value: "",
          order_key: "",
          order_value: "",
          account_type: "",
          // account_type: "patner",
          transaction_type: this.transaction_type, airline_code:this.airlinecode.toUpperCase(), payment_status:this.paymentStatus, from_book_date:this.fromdate,  to_book_date:this.todate,  from_dep_date:this.fromdepdate,  to_dep_date:this.todepdate,
          page:"patner"
        }
        this.sentrequestform = this.fb.group({
          type: ['balance', Validators.required],
          amount: ['', Validators.required],
          mode: ['', Validators.required],
          reference: ['', Validators.required],
          image: [''],
          comment: ['']
        });
      }
      this.bodydata['page_no'] = 1;
      this.accountDtl(this.element);
    // }

  }

  get f() { return this.sentrequestform.controls; }

  numberOnly(event): boolean {
    //
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  //*********shorting Start*********** */
  sortData(sort: Sort) {
    const data = this.ccData;
    if (!sort.active || sort.direction === '') {
      this.sortedList = data;
      return;
    }
    this.sortedList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'narration': return compare(a.narration, b.narration, isAsc);
        case 'amount': return compare(a.amount, b.amount, isAsc);
        case 'account_type': return compare(a.account_type, b.account_type, isAsc);
        default: return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }


  addrequest(type){
    this.submitted=false;
    this.sentrequestform.reset();
    if(type == 'balance'){
      this.usertype='balance';
      this.sentrequestform = this.fb.group({
        type: ['balance', Validators.required],
        amount: ['', Validators.required],
        mode: ['', Validators.required],
        reference: ['', Validators.required],
        image: [''],
        comment: ['']
      });
    }
    else{
      this.usertype='credit'
      this.sentrequestform = this.fb.group({
        type: ['credit', Validators.required],
        amount: ['', Validators.required],
        comment: ['']
      });
    }
    $('#sentmodal').modal('show');
  }

  preview(files) {
    if (files.length === 0){ return; }
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) { this.message = "Only images are supported."; return; }
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => { this.image = reader.result;  this.message=''; }
  }

  onFileChanged(event) {
    this.File = event.target.files[0];
  }

  addRequest(){
    this.ngxLoader.start()
    this.submitted = true;
    if (this.sentrequestform.invalid) {
      this.File=null;
      this.image=null;
      return;
    }else{
      const self=this;
      self.sharedService.loadingShow = true;
        self.sharedService.loadingShow = true;
        const bodyObject = new FormData();
        if(this.usertype == 'balance'){
          if(self.File == null){  bodyObject.append('image','');  }else{  bodyObject.append('image', self.File, self.File.name);  }
          bodyObject.append('to_user_id', (self.detail_data.share_by_user_id).toString());
          bodyObject.append('type', self.sentrequestform.value.type);
          bodyObject.append('amount', self.sentrequestform.value.amount);
          bodyObject.append('mode', self.sentrequestform.value.mode);
          bodyObject.append('reference', self.sentrequestform.value.reference);
          bodyObject.append('comment', self.sentrequestform.value.comment);
      }else{
          bodyObject.append('to_user_id', (self.detail_data.share_by_user_id).toString());
          bodyObject.append('type', self.sentrequestform.value.type);
          bodyObject.append('amount', self.sentrequestform.value.amount);
          bodyObject.append('comment', self.sentrequestform.value.comment);
      }


        this.sharedService.postRequest('v1/request/send_new',bodyObject).subscribe((res:any)=> {
          if(res.replyCode == 'success'){
            this.File=null;
            this.submitted = false;
            this.sentrequestform.reset();
            this.ngxLoader.stop();
            this.toastr.successToastr(res.replyMsg, 'Success');
            this.image=null;
          }else{
            this.image=null;
            this.File=null;
            this.ngxLoader.stop()
            this.toastr.errorToastr(res.replyMsg, 'Error');
          }
          $('#sentmodal').modal('hide');
        },err=>{
          this.ngxLoader.stop();
         if(err.error.replyMsg.message){
            this.submitted = false;
            this.image=null;
            this.File=null;
            this.toastr.errorToastr(err.error.replyMsg.message, 'Error');
            $('#sentmodal').modal('hide');
            this.sentrequestform.reset();
          }else{
            this.submitted = false
            this.image=null;
            this.File=null;
            $('#sentmodal').modal('hide');
            this.toastr.errorToastr(err.error.replyMsg, 'Error');
          }
        });
  }

  }

  SentRequest(){
    // this.RData=data;
    this.submitted=false;
    this.sentrequestform.reset();
    this.sentrequestform = this.fb.group({
      type: ['balance', Validators.required],
      amount: ['', Validators.required],
      mode: ['', Validators.required],
      reference: ['', Validators.required],
      image: [''],
      comment: ['']
    });
    $('#sentmodal').modal('show');
  }

  accountDtl(element) {
    const self = this;

    // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      this.sharedService.post('v1/account/company_account_details_new', element).subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.ccData = res.data;
          this.TotalCount = res.totalRecords;
          if ((res.data).length > 0) {
            this.data = res.data;
            this.retrn = res.data.return_flight == 0 ? "Return" : "Oneway";
            this.compDtl2 = res.company_details;
            // this.creditCount=res.credit_count;
            this.keys = Object.keys(this.data[0]);
          } else {
            this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
          }
        } else {
          self.toastr.errorToastr("Result Not Found", "Error")
        }
      }, err => {
        this.toastr.errorToastr(err.error.replyMsg, "Error");
      });
    // }
  }

  get ab() { return this.addaccountValue.controls }


  ReverseCredit(data){
    var self = this;
    this.old_credit_limit = data.amount
    this.old_available_balance = data.available_balance
    var request_data={
      credit_reversal_for:(data.id),
      narration:"Credit Reversed",
      share_inventorie_id: this.detail_data.share_inventorie_id,
      share_to_user_id: this.detail_data.share_to_user_id,
      share_by_user_id: this.detail_data.share_by_user_id,
    }
    self.sharedService.post('v1/account/reverse_credit_new', request_data).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.creditRevrsListPartner();
        self.toastr.successToastr(res.replyMsg, 'Success');
        this.accountDtl(this.element);
      }
    }, err => {
      self.submitted = false;
      this.creditRevrsListPartner();
      self.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }

  accountShow() {
    var data = JSON.parse(sessionStorage.getItem("account_detail_data"))

    $('#addBalance').modal('show');
    this.old_credit_limit = data.amount.toString()
    this.old_available_balance = data.amount.toString()
    // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      this.addaccountValue.patchValue({
        share_inventorie_id: data.share_inventorie_id.toString(),
        share_to_user_id: data.share_to_user_id.toString(),
        share_by_user_id: this.detail_data.share_by_user_id.toString(),
      })
    // }

  }

  clAccount() {
    $('#addBalance').modal('hide');
    $('#reverseList').modal('hide');
    this.submitted = false;
  }

  addAccount() {
    var self = this;
    self.submitted = true;
    if (self.addaccountValue.invalid) {
      return;
    } else {
      var request_data = self.addaccountValue.value;

      var addBalanceData = 'v1/account/add_balance'
      // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
      //   var addBalanceData = 'v1/account/add_balance'
      // }
      self.sharedService.postRequest(addBalanceData, request_data).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            this.accountData = res.data;
            self.submitted = false;
            $('#addBalance').modal('hide');
            var cl = Number(this.detail_data.amount) + Number(request_data.amount)
            // if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
              var data2 = {
                share_inventorie_id: this.detail_data.share_inventorie_id.toString(),
                share_to_user_id: this.detail_data.share_to_user_id.toString(),
                share_by_user_id: this.detail_data.share_by_user_id.toString(),
                amount: cl.toString()
              }
              sessionStorage.setItem("account_detail_data", JSON.stringify(data2))
              this.accountDtl(this.element)
            }
            self.addaccountValue.reset();
            $('#addBalance').modal('hide');
            self.submitted = false;
            self.toastr.successToastr(res.replyMsg, 'Success');
        } else {
          self.submitted = false;
          self.addaccountValue.reset();
          $('#addBalance').modal('hide');
          self.toastr.errorToastr(res.error.replyMsg, 'Error');
        }
      }, error => {
        self.submitted = false;
        self.addaccountValue.reset();
        $('#addBalance').modal('hide');
        self.toastr.errorToastr(error.error.replyMsg);
      });
    }
  }

  SearchAirlineCode(){
    if(this.airlinecode.length == 2){
      this.searchreport()
    }
  }

  onChangeStatus(event: any) {
    this.transactionStatus = event;
    this.onSearch()
  }

  onChangeAccountStatus(event: any) {
    this.accountStatus = event;
    this.onSearch()
  }

  onChangePaymentStatus(event: any) {
      this.paymentStatus = Number(event);
      this.onSearch()
  }

  searchreport(){
    if(this.fromdate != '' && this.todate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.fromdepdate != '' && this.todepdate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.airlinecode != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else{
      this.searchreportbool=true;
    }
  }

  onSearch() {
    var element;
    if (this.fromdate != '') {
      this.fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');
    } else {
      this.fromdate = ''
    }
    if (this.todate != '') {
      this.todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
    } else {
      this.todate = ''
    }
    if (this.fromdepdate != '') {
      this.fromdepdate = this.datePipe.transform(new Date(this.fromdepdate), 'yyyy-MM-dd');
    } else {
      this.fromdepdate = ''
    }
    if (this.todepdate != '') {
      this.todepdate = this.datePipe.transform(new Date(this.todepdate), 'yyyy-MM-dd');
    } else {
      this.todepdate = ''
    }
    if (this.airlinecode != '') {
      this.airlinecode = this.airlinecode;
    } else {
      this.airlinecode = ''
    }
    this.searchFlagReset = true;
    var requestdata = {
      // share_inventorie_id: this.detail_data.id,
      share_inventorie_id: this.detail_data.share_inventorie_id,
      share_to_user_id: this.detail_data.share_to_user_id,
      share_by_user_id: this.detail_data.share_by_user_id,
      page_limit: this.TotalCount,
      page_no: "",
      // search_value: String(this.inputSearch.nativeElement.value),
      search_value: '',
      order_key: "",
      order_value: "",
      account_type: this.accountStatus,
      // transaction_type: this.transaction_type,
      airline_code:this.airlinecode.toUpperCase(), payment_status:this.paymentStatus, from_book_date:this.fromdate,  to_book_date:this.todate,  from_dep_date:this.fromdepdate,  to_dep_date:this.todepdate,
      transaction_type:this.transactionStatus, page:"patner",
    }

    this.sharedService.post('v1/account/company_account_details_new', requestdata).subscribe((res) => {
      if (res.replyCode == "success") {
        this.searchFlagReset = true;
        this.data = res.data;
        this.totalpage = (res.count)/(requestdata.page_limit);  this.TotalCount = res.totalRecords;
      }
    }, err => {
    })
    // this.accountDtl(requestdata);
  }
  onReset() {

    this.inputSearch=null;
    this.searchFlagReset = false;
    this.element['search'] = '';
    this.fromdate = '';
    this.todate = '';
    this.fromdepdate = '';
    this.todepdate = '';
    this.airlinecode = '';
    this.paymentStatus = '';
    this.transaction_type = '';
    this.account_type = '';
    this.accountDtl(this.element);

    // this.inputSearch.nativeElement.value = '';
    // this.searchFlagReset = false;
    // this.element['search'] = '';
    // this.accountDtl(this.element);
  }
  onPageChange(event) {

    var requestdata = {
      share_inventorie_id: this.detail_data.share_inventorie_id,
      share_to_user_id: this.detail_data.share_to_user_id,
      share_by_user_id: this.detail_data.share_by_user_id,
      page_limit: this.TotalCount,
      page_no: '',
      search_value: "",
      order_key: "",
      order_value: "",
      account_type:"",
      transaction_type: this.transaction_type,airline_code:this.airlinecode.toUpperCase(), payment_status:this.paymentStatus, from_book_date:this.fromdate,  to_book_date:this.todate,  from_dep_date:this.fromdepdate,  to_dep_date:this.todepdate,
      page:"patner"

    }
    this.accountDtl(requestdata);
  }


  download(data) {
    var requestdata = {

      share_inventorie_id: this.detail_data.share_inventorie_id,
      share_to_user_id: this.detail_data.share_to_user_id,
      share_by_user_id: this.detail_data.share_by_user_id,
      page_limit: this.TotalCount,
      page_no: "1", search_value: "", order_key: "", order_value: "",
      account_type: "",
      transaction_type: this.transaction_type, airline_code:this.airlinecode.toUpperCase(), payment_status:this.paymentStatus, from_book_date:this.fromdate,  to_book_date:this.todate,  from_dep_date:this.fromdepdate,  to_dep_date:this.todepdate,
      page:"patner"
    }
    const self = this;
      this.sharedService.post('v1/account/company_account_details_new', requestdata).subscribe((res: any) => {
        if (res.replyCode == "success") {
          if ((res.data).length > 0) {
            this.data1 = res.data;
            setTimeout(() => {
              this.exportdata();
            }, 1300);
          } else {
            this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
          }
        } else {
          self.toastr.errorToastr("Result Not Found", "Error")
        }
      }, err => {
        this.toastr.errorToastr(err.error.replyMsg, "Error");
      });
    // setTimeout(() => {
    //   // $('#Exportmodal').modal('show');
    //   this.exportdata(data);
    // }, 1000);
  }

  exportdata(): void {
    /* table id is passed over here */
    let element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Excel-Partner-Report.xlsx');

  }

  sortfun(orderkey, type) {
    if (orderkey == 'narration' && type == 'asc') {
      this.sort_narration = 'asc';
    } else {
      this.sort_narration = 'desc';
    }
    if (orderkey == 'amount' && type == 'asc') {
      this.sort_amount = 'asc';
    } else {
      this.sort_amount = 'desc';
    }
    if (orderkey == 'account_type' && type == 'asc') {
      this.sort_account_type = 'asc';
    } else {
      this.sort_account_type = 'desc';
    }

    this.searchFlagReset = true;
    var DataSearch = {
      share_inventorie_id: this.detail_data.share_inventorie_id,
      share_to_user_id: this.detail_data.share_to_user_id,
      share_by_user_id: this.detail_data.share_by_user_id,
      page_limit: 10,
      page_no: "",
      search: "",
      //search: String(this.inputSearch.nativeElement.value) ? String(this.inputSearch.nativeElement.value) : "",
      order_key: orderkey,
      order_value: type,
      account_type:"patner",
      transaction_type: this.transaction_type
    }
    this.accountDtl(DataSearch);
  }


  // viewDetail(element) {
  //   var self = this;
  //   var Objects = {
  //     user_id: this.userdata.id,
  //     flight_booking_id: element.flight_booking_id
  //   }
  //   console.log("flightBookingDetail",Objects);
  //   // return false;

  //   this.sharedService.post('v1/flight/flight_booking_details', Objects).subscribe((res) => {
  //     if (res.replyCode == 'success') {
  //       var counter = 0;
  //       this.bgBannerPath = res.bannerimgBasePath;
  //       setTimeout(() => {
  //         this.imgPath = res.imgBasePath;
  //         res.data.flight_booking_details.forEach(element => {

  //           if (element.ticket_cancel_at == null) {
  //             element.t_status = 'booked'
  //           } else {
  //             counter += 1;
  //             element.t_status = 'cancelled'
  //           }
  //         });
  //         if (counter == res.data.total_book_seats) {
  //           res.data.ticket_status = "cancelled"
  //         } else {
  //           res.data.ticket_status = "booked"
  //         }
  //         this.bookData = res.data;

  //         this.bookData.cal_base_fare = ((this.bookData.flightData.flight_fare_taxes[0].base_fare * (this.bookData.adult + this.bookData.children)) + (this.bookData.flightData.infant_base_price * this.bookData.infant));
  //         this.bookData.cal_fees_and_taxes = (this.bookData.flightData.flight_fare_taxes[0].fee_taxes * (this.bookData.adult + this.bookData.children));
  //         this.bookData.servicecharge = (this.bookData.total_amount - (this.bookData.cal_base_fare) - (this.bookData.cal_fees_and_taxes));

  //         this.bookData.ticket_id = element.flight_booking_id;
  //         this.flightData = res.data;
  //         self.returnFlight = res.data.flightData.return_flight;
  //         self.flt_bokg_dtl = res.data.flight_booking_details;
  //       }, 100);
  //       $('#genrepo').modal('show');
  //     }
  //   });

  // }
  printpreview() {
    var printW = window.open("");
    var html = document.getElementById('contentToConvert1').innerHTML;

    printW.document.write('<html><head><title>Booking Details</title>');
    printW.document.write(`<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" type="text/css" />`);
    printW.document.write(`<style>
     .col-3 {-webkit-box-flex: 0;flex: 0 0 25%;max-width: 25%;}
     .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
     table td{border-bottom:1px solid#ddd;padding:5px;border-collapse: collapse;} table th {border-collapse: collapse;border-bottom:1px solid#ddd;padding:5px;font-weight: 600;color: #000;} table{ width: 100%; border-spacing:1px;border-collapse: separate;}.row{display: flex;flex-wrap: wrap;width:100%;}.col-md-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}.img-box {width: 50px;height: 50px;border: 1px solid #ddd;object-fit:cover;}
     .border {border: 1px solid #dee2e6!important;}
     table{margin-bottom:5px !important;}
     .ph-text{height:220px !important;font-size:10px !important;line-height:13px !important;}
     .row {display: -webkit-box;display: flex;flex-wrap: wrap;}
     .text-left {text-align: left!important;}
     .img-profile {width: 100%;height: auto;-o-object-fit: contain;object-fit: contain;max-height: 70px;}
      .text-center {text-align: center!important;}
      .col-6 {-webkit-box-flex: 0;flex: 0 0 50%;max-width: 50%;}
      .head-clr {color: #ff6a00;}.fw-500 {font-weight: 500;}
      .m-0 {margin: 0!important;}.w-100 {width: 100%!important;}.text-center {text-align: center!important;}
      .bg-white {background-color: #fff!important;}
      table {border-collapse: collapse;}
      .table {width: 100%;color: #212529;}
      .col-lg-4 {-webkit-box-flex: 0;flex: 0 0 33.333333%;max-width: 33.333333%;}
      .wp-100{width:100%;} .wp-30{width:30% !important;}
      .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        margin-bottom:.5rem !important;font-weight: 500;line-height: 1.2;}
      .mytbl td {padding: 0.5rem;margin-bottom: 0rem !important;border-bottom:1px solid#ddd;}
      .mytbl td {padding: 0.5rem !important;vertical-align: middle;padding-left: 0.75rem !important;}
      .table td, .table th {padding: .75rem;vertical-align: top;border-top: 1px solid #dee2e6;}
      .border-bottom {border-bottom: 1px solid #dee2e6!important;}
      .img-box-fix {width: 50px;height: 30px;-o-object-fit: contain;object-fit: contain;}
      img {vertical-align: middle;border-style: none;}
      .textclr {color: #828282;}
      .ft-12 {font-size: 12px !important;}
      .align-self-center {align-self: center!important;}
      .table-responsive {display: block;width: 100%;overflow-x: auto;-webkit-overflow-scrolling: touch;}
      .d-none {display: none!important;}.text-dark {color: #343a40!important;}.h6, h6 {font-size: 1rem;}
      .form-control:disabled, .form-control[readonly] {background-color: #e9ecef !important;opacity: 1;}
      .form-control {display: block;width: 100%;height: calc(1.5em + .75rem + 2px);padding: .375rem .75rem;font-size: 1rem;
        font-weight: 400;line-height: 1.5;color: #495057;background-color: #fff;background-clip: padding-box;border: 1px solid #ced4da;border-radius: .25rem;-webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
      .prnt-h{line-height:32px !important;}
      .form-control-1{border:1px solid#ddd;border-radius:5px;background-color:#e9ecef;color:#303030;padding:8px;}
      .w-10pr{width:10%;}.w-14pr{width:14%;}.w-25pr{width:25%;}
      .pmt-10{margin-top:10px;}
      .mytbl tr td{padding:2px;}
      .p-Size{font-size:12px;}.pmb-1{margin-bottom:10px !important;}.pmb-2{margin-bottom:15px !important; }
      .mytbl tr td{padding:2px;}.pmt-1{margin-bottom:10px !important;}.pmt-2{margin-bottom:15px !important; }
      .pr-dnone{display:none !important;}
      </style>`);
    printW.document.write('</head><body >');
    printW.document.write(html);
    printW.document.write('</body></html>');



    printW.document.close();
    printW.focus();
    printW.print();
    // if(this.commonService.osname != "Mac OS"){
    //   printW.close();
    // }else{
    //   console.log("not Windows os")
    // }
  }

  sellerInfo(event) {
    if (event.checked == true) {
      this.sellerDtl = true;
    } else {
      this.sellerDtl = false;
    }
  }
  durationdiff(dep_time, arr_time, dep_date, type) {
    //
    var dep_hr = parseInt(dep_time.split(':')[0]);
    var arr_hr = parseInt(arr_time.split(':')[0]);
    //
    if (arr_hr < dep_hr) {
      var nwdate;
      if (!dep_date) {
        nwdate = new Date();
      } else {
        nwdate = new Date(dep_date);
      }

      var newdate = nwdate.setDate(nwdate.getDate() + 1);
      if (type == 'oneway') {
        this.return_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
      } else {
        this.rreturn_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');
      }

    } else {
      this.return_d_date = dep_date;
      this.rreturn_d_date = dep_date;
    }
    //
    var start_date = dep_date + " " + dep_time;
    var end_date = this.return_d_date + " " + arr_time;
    //
    return this.getDataDiff(new Date(start_date.toString()), new Date(end_date.toString()));
  }
  getDataDiff(s_date, e_date) {
    var diff = e_date.getTime() - s_date.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return hours + " hrs " + ": " + minutes + " min";
  }

  creditRevrsListPartner(){
    var sendReqData ={
      "share_inventorie_id":this.detail_data.share_inventorie_id,
      "share_to_user_id":this.detail_data.share_to_user_id,
      "share_by_user_id": this.detail_data.share_by_user_id,
      "page_limit": "10",
      "page_no":"1",
      "search_value": "",
      "order_key": "",
      "order_value": "",
      "account_type": "patner"
    }
    this.sharedService.post('v1/account/credit_not_reversed_list',sendReqData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        // this.ccData = res.data;
        if ((res.data).length > 0) {
          this.reversData = res.data;
          // this.creditCount=res.credit_count;
          this.keys = Object.keys(this.reversData);
          this.TotalCount1 = res.totalRecords
          $('#reverseList').modal('show');
        } else {
          if(this.reversData){
            this.reversData=!this.reversData;
          }
          this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
          $('#reverseList').modal('show');
        }
      } else {
        this.toastr.errorToastr("Result Not Found", "Error")
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    });

  }

  viewDetail(element){
    this.booking_id = element.flight_booking_id
    sessionStorage.setItem("booking_id", JSON.stringify(this.booking_id));
    window.open(RedirectionPath.processUrl('booking-summary'), '_blank');
  }

}



import { Component, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router} from '@angular/router';
import { SharedserviceService, CommonService } from 'src/app/services';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var $: any;

@Component({
  selector: 'app-emailsetting',
  templateUrl: './emailsetting.component.html',
  styleUrls: ['./emailsetting.component.scss'],
  providers: [NgxUiLoaderService]
})
export class EmailsettingComponent implements OnInit {
  ccData: any;
  totalpage: any;
  errfail: any;
  succes: any;
  type: any;
  userdata: any;
  acceptApicheck: any;
  status: any;
  public createForm: FormGroup;
  public emailTesting: FormGroup;
  submitted: boolean = false;
  testMailSubmitted: boolean = false;
  show: boolean = false;
  disableinput: boolean = false;

  bodydata: any = {}; TotalCount: any; data: any; keys: any; public page: number = 1; start = 0; msg: any; FormId: any; flag: boolean = false;


  constructor(
    private fb: FormBuilder,
    private ngxLoader: NgxUiLoaderService,
    public sharedService: SharedserviceService,
    public router: Router,
    public commonService: CommonService,
    private toastr: ToastrManager
  ) {
    this.userdata = JSON.parse(localStorage.getItem("user"));
    //
  }


  ngOnInit() {

    this.createForm = this.fb.group({
      host: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      port: ['', Validators.required]
      // host: ['smtp.gmail.com'],
      // username: ['', Validators.required],
      // password: ['', Validators.required],
      // port: ['456']
    });

    this.emailTesting = this.fb.group({
      subject: ['', Validators.required],
      email: ['', Validators.required],
      message: ['', Validators.required],
    });


    this.viewEmailSetting(this.bodydata);
  }
  get f() { return this.createForm.controls; }
  get fm() { return this.emailTesting.controls; }

  viewEmailSetting(bodydata) {
    var email = bodydata.username + "@gmail.com";
    bodydata.username = email;
    this.ngxLoader.start();
    this.sharedService.post('v1/mail_server_setting/view', bodydata).subscribe((res) => {
      if (res.replyCode == 'success') {
        this.ccData = res.data;
        this.ngxLoader.stop();
      } else {
        this.ngxLoader.stop();
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    }, err => {
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }

  updateForm() {
    this.submitted = false;
    this.createForm.reset();
    $('#updateEmailForm').modal('show');
  }

  updateEmail() {
    var self = this;
    this.submitted = true;
    if (this.createForm.valid) {
      var Object = {
        // "mail_setting_id": this.ccData.id,
        "username": self.createForm.value.username,
        "password": self.createForm.value.password,
        "host": self.createForm.value.host,
        "port": self.createForm.value.port
        // "host": "smtp.gmail.com",
        // "port": "456"
      }
      var email = Object.username + "@gmail.com";
      Object.username = email;
      self.sharedService.postRequest('v1/mail_server_setting/update_new', Object).subscribe((res: any) => {
      // self.sharedService.postRequest('v1/mail_server_setting/update', Object).subscribe((res: any) => {
        if (!res.error) {
          self.toastr.successToastr(res.replyMsg, 'Success');
          self.viewEmailSetting(self.bodydata);
          $('#updateEmailForm').modal('hide');
          this.createForm.reset();
        } else {
          self.toastr.errorToastr(res.error.replyMsg, 'Error');
        }
      }, error => {
        self.toastr.errorToastr(error.error.replyMsg, 'Error');
      });
    }
  }
  omit_special_char(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 95 || k == 45 || k == 32 || (k >= 46 && k <= 57));
  }

  checkMail() {
    var self = this;
    this.testMailSubmitted = true;

    if (this.emailTesting.valid) {
      var Object = {
        "subject": self.emailTesting.value.subject,
        "email": self.emailTesting.value.email,
        "message": self.emailTesting.value.message,
      }
      this.ngxLoader.start();
      self.sharedService.post('v1/mail_server_setting/test_mail', Object).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          this.succes = res;
          $('#mailTest').modal('hide');
          this.ngxLoader.stop();
          self.toastr.successToastr(res.replyMsg, 'Success');
        } else {
          this.ngxLoader.stop();
          self.toastr.errorToastr(res.replyMsg, 'Error');
        }
      }, error => {
        this.ngxLoader.stop();
        this.toastr.errorToastr(error['error']['replyMsg'], 'Error');
      });
    }
  }


  addEmail() {


    var self = this;
    self.submitted = true;
    if (self.createForm.invalid) {
      return;
    } else {
      self.submitted = true;
      self.sharedService.postRequest('v1/mail_server_setting/add', self.createForm.value).subscribe((res: any) => {
        if (!res.error) {
          if (res.replyCode == 'success') {
            self.viewEmailSetting(this.bodydata);
            self.submitted = false;
            self.submitted = false;
            self.createForm.reset();
            self.toastr.successToastr(res.replyMsg, 'Success');
          } else {
            self.submitted = false;
            self.toastr.errorToastr(res.replyMsg, 'Error');
          }
        } else {
          self.submitted = false;
          self.toastr.errorToastr(res.error.replyMsg, 'Error');
        }
      }, error => {
        self.submitted = false;
        self.toastr.errorToastr(error.error.replyMsg);
      });
    }
  }

  checkPwd() {
    this.show = !this.show;
  }
  Mailpopup() {
    $('#mailTest').modal('show');
  }
  closeClickemail() {
    this.submitted = false;
    this.emailTesting.reset();
    this.testMailSubmitted=false;
    $('#mailTest').modal('hide');
  }
}

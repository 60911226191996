import { Injectable } from '@angular/core';
import { ManagedataService } from './manage-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { SharedserviceService } from './sharedservice.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { debounceTime } from 'rxjs/operators';
declare var $ :any;
@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {
  inventyFrom:any=[];
  constructor(public baseService: SharedserviceService,public toastr:ToastrManager, public manageDataService: ManagedataService,private ngxLoader: NgxUiLoaderService,public router:Router) { }

  onDepartureFlightList(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/common/dep_city', payload).pipe(debounceTime(500)).subscribe((data: any) => {
      if (data.replyCode == "success") {
        if (data.data.length > 0) {
          data.data.sort((a,b) => a.departure_city_name.localeCompare(b.departure_city_name));
          data.data.forEach((element: any) => {
            Object.assign(element, { status: 1 });
          });
        }
        this.manageDataService.SetDepartureArray(data.data);
        this.ngxLoader.stop();
      } else {
        // this.messageService.showError(data.replyMsg);
        this.manageDataService.SetDepartureArray([]);

        this.ngxLoader.stop();
      }
    }, err => {

      this.ngxLoader.stop();
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.DEPARTURE_CITY_CUSTOM}`, err)
    })
  }
  onArrivalFlightList(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/common/arr_city', payload).pipe(debounceTime(500)).subscribe((data: any) => {
      if (data.replyCode == "success") {
        if (data.data.length > 0) {
          data.data.sort((a,b) => a.arrival_city_name.localeCompare(b.arrival_city_name));
          data.data.forEach((element: any) => {
            Object.assign(element, { status: 1 });
          });
        }
        this.manageDataService.SetArrivalArray(data.data);

        this.ngxLoader.stop();
      } else {
        // this.messageService.showError(data.replyMsg);
        this.manageDataService.SetArrivalArray([]);

        this.ngxLoader.stop();
      }
    }, err => {

      this.ngxLoader.stop();
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.ARRIVAL_CITY_CUSTOM}`, err)
    })
  }

  onDepartureFlightDateList(payload: any) {
    this.manageDataService.SetDepartureDateArray([]);
    this.ngxLoader.start();
    this.baseService.post('v1/common/dates', payload).pipe(debounceTime(500)).subscribe((data: any) => {
      if (data.replyCode == "success") {
        if (data.data.length > 0) {
          this.manageDataService.SetDepartureDateArray(data.data);
          this.ngxLoader.stop();
        }else{
          this.manageDataService.SetDepartureDateArray([]);
          this.ngxLoader.stop();
        }
      } else {
        // this.messageService.showError(data.replyMsg);
        this.manageDataService.SetDepartureDateArray([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.DEPARTURE_DATE_CUSTOM}`, err)
    })
  }
  onArrivalFlightDateList(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/common/return_dates', payload).pipe(debounceTime(500)).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetReturnDateArray(data.data);

        this.ngxLoader.stop();
      } else {
        // this.messageService.showError(data.replyMsg);
        this.manageDataService.SetReturnDateArray([]);

        this.ngxLoader.stop();
      }
    }, err => {

      this.ngxLoader.stop();
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.RETURN_DATE_CUSTOM}`, err)
    })
  }
  onDepartureArrivalFlightList(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/city/list', payload).pipe(debounceTime(500)).subscribe((data: any) => {
      if (data.replyCode == "success") {
        var newArray: any = [];
        if (data.data.length > 0) {
          data.data.forEach((element: any) => {
            var obj: any = {
              airport_code: element.airport_code,
              airport_id: element.airport_id,
              airport_name: element.airport_name,
              departure_airport_id: element.airport_id,
              departure_city_code: element.city_code,
              departure_city_id: element.city_id,
              departure_city_name: element.city_name,
              arrival_airport_id: element.airport_id,
              arrival_city_code: element.city_code,
              arrival_city_id: element.city_id,
              arrival_city_name: element.city_name,
              status: element.status,
            };
            newArray.push(obj);
          });
        } else {
          newArray = [];
        }
        setTimeout(() => {
          localStorage.setItem('regularCityResponse', JSON.stringify(newArray))
          var cityData: any = localStorage.getItem('regularCityResponse')
          this.manageDataService.SetRegularCityArray(JSON.parse(cityData));
          // this.manageDataService.SetRegularCityArray(newArray);
        }, 1000);

        this.ngxLoader.stop();
      } else {
        // this.messageService.showError(data.replyMsg);

        this.ngxLoader.stop();
      }
    }, err => {

      this.ngxLoader.stop();
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.DEPARTURE_ARRIVAL_CITY_REGULAR}`, err)
    })
  }

  onAccountlistSale(payload: any) {
    this.ngxLoader.start();
    // this.manageDataService.SetSearchingFlightLoaderStatus(true);
    this.baseService.post('v1/accounting/complete_sale_italy', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        console.log("onAccountlistSale>>>",data);
        this.manageDataService.SetAccountCompleteSaleItaly(data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetAccountCompleteSaleItaly([]);
        this.manageDataService.SetFlightImagePath('');
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onBookingViewItaly(payload: any) {
    this.ngxLoader.start();
    // this.manageDataService.SetSearchingFlightLoaderStatus(true);
    this.baseService.post('v1/accounting/view_booking_italy', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        console.log("View Booking>>>>>>",data);
        this.manageDataService.SetBookingViewItaly(data);
        this.manageDataService.SetFlightImagePath(data.imgBasePath);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetBookingViewItaly([]);
        this.manageDataService.SetFlightImagePath('');
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onCustomFlightSearch(payload: any) {
    this.ngxLoader.start();
    // this.manageDataService.SetSearchingFlightLoaderStatus(true);
    this.baseService.post('v1/common/search', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetSearchFlightResponse(data);
        this.manageDataService.SetFlightImagePath(data.imgBasePath);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetSearchFlightResponse([]);
        this.manageDataService.SetFlightImagePath('');
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  // onRegularFlightSearch(payload: any) {
  //   this.ngxLoader.start();
  //   // this.manageDataService.SetSearchingFlightLoaderStatus(true);
  //   var airlinesArray: any = [];
  //   this.baseService.post('v1/fit/airline_list', payload).subscribe((data: any) => {
  //     if (data.replyCode == "success") {
  //       if (data.data.length > 0) {
  //         var sendRequestData: any = payload;
  //         setTimeout(() => {
  //           data.data.forEach((element: any) => {
  //             sendRequestData.prefered_airline = element;
  //             this.baseService.post('v1/fit/search', sendRequestData).subscribe((data: any) => {
  //               airlinesArray = airlinesArray.concat(data.data);
  //               this.manageDataService.SetSearchFlightResponse({ data: airlinesArray });
  //               this.manageDataService.SetFlightImagePath(data.imgBasePath);

  //               this.ngxLoader.stop();
  //               // this.manageDataService.SetSearchingFlightLoaderStatus(false);
  //             });
  //           });
  //         }, 1000);
  //       } else {
  //         airlinesArray = [];
  //         this.manageDataService.SetSearchFlightResponse({ data_oneway: airlinesArray });
  //         this.manageDataService.SetFlightImagePath(data.imgBasePath);

  //         this.ngxLoader.stop();
  //         // this.manageDataService.SetSearchingFlightLoaderStatus(false);
  //       }
  //     } else {
  //       airlinesArray = [];
  //       this.manageDataService.SetSearchFlightResponse({ data_oneway: airlinesArray });
  //       this.manageDataService.SetFlightImagePath(data.imgBasePath);

  //       this.ngxLoader.stop();
  //       // this.manageDataService.SetSearchingFlightLoaderStatus(false);
  //     }
  //   }, err => {

  //     this.ngxLoader.stop();
  //     // this.manageDataService.SetSearchingFlightLoaderStatus(false);
  //     // this.messageService.showError(err.error['replyMsg']);
  //     // console.log(`${ApiMethods.SEARCH_FLIGHT_REGULAR}`, err)
  //   })
  // }
  onRegularFlightSearch(payload: any) {
    this.ngxLoader.start();
    var airlinesArray: any = [];
    var airlinesArrayReturn: any = [];
    var sendRequestData: any = payload;
    // sendRequestData.prefered_airline = element;
    this.baseService.post('v1/fit/search', sendRequestData).pipe(debounceTime(500)).subscribe((data: any) => {
        if (data.replyCode == "success") {
          if(data.onward_length > 0 ){
              airlinesArray = data.onward;
              this.manageDataService.SetSearchFlightOnewayResponse({ data: airlinesArray });
              this.manageDataService.SetFlightImagePath(data.imgBasePath);
              this.ngxLoader.stop();
          }
          if(data.return_length > 0){
              airlinesArrayReturn = data.return;
              this.manageDataService.SetSearchFlightReturnResponse({ data: airlinesArrayReturn });
              this.manageDataService.SetFlightImagePath(data.imgBasePath);
              this.ngxLoader.stop();
          }
          if(data.length>0 && data.return_length==0){
              airlinesArray = data.data;
              this.manageDataService.SetSearchFlightResponse({ data: airlinesArray });
              this.manageDataService.SetFlightImagePath(data.imgBasePath);
              this.ngxLoader.stop();
          }
          //   else{
          //     airlinesArray = [];
          //     this.manageDataService.SetSearchFlightResponse({ data_oneway: airlinesArray });
          //     this.ngxLoader.stop();
          // }
        } else {
          airlinesArray = [];
          this.manageDataService.SetSearchFlightResponse({ data_oneway: airlinesArray });
          this.manageDataService.SetFlightImagePath(data.imgBasePath);
          this.ngxLoader.stop();
          // this.manageDataService.SetSearchingFlightLoaderStatus(false);
        }
        this.ngxLoader.stop();
        // this.manageDataService.SetSearchingFlightLoaderStatus(false);
    }, err => {
      this.ngxLoader.stop();
      // this.manageDataService.SetSearchingFlightLoaderStatus(false);
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.SEARCH_FLIGHT_REGULAR}`, err)
    })
  }
  onFITFareRuleList(inventory_form:any,payload:any){
    // this.ngxLoader.start()
    var fareRuleList :any=[];
    var sendRequestData:any = payload;
    var apiFareRule = 'v1/fit/fare_rule/'
    this.baseService.post(apiFareRule+''+inventory_form, sendRequestData).subscribe((data:any)=>{
      if(data.replyCode == 'success'){
        console.log("data==>>>>248",data);
          fareRuleList = data
          this.manageDataService.SetFareRuleList(fareRuleList);
        this.ngxLoader.stop();
      }else{
        this.ngxLoader.stop();
      }
    })
  }
  onGgnActiveUser(){
    this.ngxLoader.start();
    this.baseService.get('v1/ggn/active_users').subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.manageDataService.SetggnActiveUserData(res.data);
        this.ngxLoader.stop()
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  ggnList(requestData:any){
    this.ngxLoader.start();
    this.baseService.post('v1/ggn/list',requestData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.manageDataService.SetGGNlistDataResponse(res.data);
        this.ngxLoader.stop()
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  onAddGGNFormArray(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/ggn/add_ggn',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.manageDataService.SetggnFormDataValue(res.data);
        this.ngxLoader.stop()
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  onGgnEditData(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/ggn/edit_ggn',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        // this.manageDataService.SetggnFormDataValue(res.data);
        this.ngxLoader.stop()
        this.toastr.successToastr(res.message, 'Edit Successfully');
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  onGgnDeleteData(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/ggn/delete',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        // this.manageDataService.SetggnFormDataValue(res.data);
        this.ngxLoader.stop()
        this.toastr.successToastr(res.message, 'Delete Successfully');
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  onGgnStatusChange(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/ggn/change_status',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        // this.manageDataService.SetggnFormDataValue(res.data);
        this.ngxLoader.stop()
        this.toastr.successToastr(res.message, 'Change Status Successfully');
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  onAddUserList(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/ggn/user_list',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.manageDataService.SetaddUserListResponse(res.data);
        this.ngxLoader.stop()
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  onAddUserRemoveList(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/ggn/add_user',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        // this.manageDataService.SetaddUserListResponse(res.data);
        this.ngxLoader.stop()
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }

  onCustomMessage(payload:any){
    this.baseService.post('v1/whatsapp/custom',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        $('#customMessageModal').modal('hide');
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
  }
  onAddAgencyData(payload:any){
    this.baseService.post('v1/users/register_user',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.manageDataService.SetUserAgencyData(res.data)
        $('#addUserModal').modal('hide');
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    });
  }
  onloadstatelist(){
    this.baseService.get('v1/all_state').subscribe((res)=> {
      if(res.replyCode == 'success'){
        this.manageDataService.SetStateListResponse(res.data)
      }else{
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    },err=>{
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }
  onB2bUpdateApi(payload:any){
    this.baseService.post('v1/fit_margin/default_markup',payload).subscribe((res)=> {
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg, "Success")
      }else{
        this.toastr.errorToastr(res.replyMsg, 'Error');
      }
    },err=>{
      this.toastr.errorToastr(err['error']['message'], 'Error');
    });
  }
  onMarkupListData(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/markup_list',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.manageDataService.SetMarkupListResponse(res.data);
        this.ngxLoader.stop()
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  onFitMarginUserList(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/user_list',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.manageDataService.SetFitMarginUserList(res.data);
        this.ngxLoader.stop()
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  onGroupListResponse(){
    this.ngxLoader.start();
    this.baseService.get('v1/fit_margin/group_list').subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.manageDataService.SetGroupListData(res.data);
        this.ngxLoader.stop()
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  onEditMarginList(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/edit_margin',payload).subscribe((res:any)=>{
      if(res.replyCode=='success'){
        this.toastr.successToastr(res.replyMsg, "success")
        this.router.navigateByUrl('/dashboard/fb-margin-list');
        this.ngxLoader.stop();
      }
      }, err=>{
        this.ngxLoader.stop();
        this.toastr.errorToastr(err.res.replyMsg, 'Error')
      })
  }
  onMarkupEditForm(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/edit_markup',payload).subscribe((res:any)=>{
      if(res.replyCode=='success'){
        this.toastr.successToastr(res.replyMsg, "success")
        this.ngxLoader.stop();
      }
      }, err=>{
        this.ngxLoader.stop();
        this.toastr.errorToastr(err.res.replyMsg, 'Error')
      })
  }
  onFitMarginList(payload:any){
    this.baseService.post('v1/fit_margin/margin_list',payload).subscribe((res:any)=>{
      if(res.replyCode=='success'){
        this.manageDataService.SetFitMarginList(res.data)
        this.ngxLoader.stop();
      }
    }, err=>{
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.res.replyMsg, 'Error');
    })
  }
  onFareListApi(payload:any){
    this.baseService.post('v1/fit_margin/name_list',payload).subscribe((res:any)=>{
      if(res.replyCode=='success'){
        this.manageDataService.SetNameListData(res.data)
        this.ngxLoader.stop();
      }
    }, err=>{
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.res.replyMsg, 'Error');
    })
  }
  onApiPartnerId(){
    this.ngxLoader.start();
      this.baseService.get('v1/fit_margin/partner_list').subscribe((res: any) => {
        if (res.replyCode == 'success') {
          this.manageDataService.SetApiPartnerValue(res.data);
          this.ngxLoader.stop();
        }
      }, err => {
        this.ngxLoader.stop();
        this.toastr.errorToastr(err.res.replyMsg, 'Error');
    });
  }
  onAddMarkupApi(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/add_markup',payload).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg, "Add markup successfully")
        this.ngxLoader.stop();
      }
    }, err=>{
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.res.replyMsg, 'Error');
    })
  }
  onRemoveMarkupData(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/delete_markup',payload).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg, "Success")
        this.ngxLoader.stop();
      }
    }, err=>{
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.res.replyMsg, 'Error');
    })
  }
  onAddGroupMargin(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/create_group',payload).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg, "Success")
        this.ngxLoader.stop();
      }
    }, err=>{
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.res.replyMsg, 'Error');
    })
  }
  onEditGroupMargin(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/edit_group',payload).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg, "Success")
        this.ngxLoader.stop();
      }
    }, err=>{
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.res.replyMsg, 'Error');
    })
  }
  onAddGroupApi(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/set_group',payload).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg, "Success")
        this.ngxLoader.stop();
      }
    }, err=>{
      this.ngxLoader.stop();
      this.toastr.errorToastr(err.res.replyMsg, 'Error');
    })
  }
  onDeleteGroup(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/delete_group',payload).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg, 'Success')
        this.ngxLoader.stop();
      }
    }, err=>{
      this.toastr.errorToastr(err.res.replyMsg, 'Error')
      this.ngxLoader.stop();
    }
    )
  }
  onDeleteGroupApi(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/remove_group',payload).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg, 'Success')
        this.ngxLoader.stop();
      }
    }, err=>{
      this.toastr.errorToastr(err.res.replyMsg, 'Error')
      this.ngxLoader.stop();
    }
    )
  }
  onDeleteNameApi(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/fit_margin/delete_name',payload).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg, 'Success')
        this.ngxLoader.stop();
      }
    }, err=>{
      this.toastr.errorToastr(err.res.replyMsg, 'Error')
      this.ngxLoader.stop();
    }
    )
  }

  onStaticData(data) {
    this.ngxLoader.start();
    this.baseService.post('v1/stats_today/daily_record',data).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetStaticData(data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetStaticData([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }

  dailySelfSector(data) {
    this.ngxLoader.start();
    this.baseService.post('v1/stats_today/daily_self_sector', data).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetSelfSectorData(data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetSelfSectorData([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }

  dailyPartnerSector(body) {
    this.ngxLoader.start();
    this.baseService.post('v1/stats_today/daily_partner_sector', body).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetPartnerSectorData(data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetPartnerSectorData([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }

  dailyAvilSector(body) {
    this.ngxLoader.start();
    this.baseService.post('v1/stats_today/daily_avail_secctors', body).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetAvilSectorData(data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetAvilSectorData([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }

  dailyUnsoldSector(body) {
    this.ngxLoader.start();
    this.baseService.post('v1/stats_today/daily_unsold_secctors', body).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetUnsoldSectorData(data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetUnsoldSectorData([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }

  dailySupplierSector(data) {
    this.ngxLoader.start();
    this.baseService.post('v1/stats_today/daily_supplier_sector', data).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetSupplierSectorData(data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetSupplierSectorData([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }

  onAdverList() {
    this.ngxLoader.start();
    this.baseService.get('v1/ad/list').subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetAdertisement(data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetAdertisement([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onDeleteAdvertisment(payload:any) {
    this.ngxLoader.start();
    this.baseService.post('v1/ad/delete',payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.ngxLoader.stop();
      } else {
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onDepartmentList(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_group/list', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetDepartmentValue(data.data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetDepartmentValue([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onAllStaffInventory(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/all_inventory', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetAllStaffInventoryData(data.data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetAllStaffInventoryData([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onStaffInventDelete(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/delete_inventory', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.ngxLoader.stop();
        this.toastr.successToastr(data.replyMsg, 'Success')
      } else {
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onStaffAllBuyer(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/all_buyers', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetStaffAllBuyerData(data.data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetStaffAllBuyerData([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onStaffAllSeller(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/all_seller', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetStaffAllsellerData(data.data);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetStaffAllsellerData([]);
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onSellerDelete(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/delete_seller', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.ngxLoader.stop();
        this.toastr.successToastr(data.replyMsg, 'Success')
      } else {
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onSellerAddData(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/create_seller', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.ngxLoader.stop();
        this.toastr.successToastr(data.replyMsg, 'Success')
      } else {
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onAddBuyer(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/create_buyer', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.ngxLoader.stop();
        this.toastr.successToastr(data.replyMsg, 'Success')
      } else {
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onChangeSellerStatus(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/update_status_seller', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.ngxLoader.stop();
        this.toastr.successToastr(data.replyMsg, 'Success')
      } else {
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onChangeStatusInventory(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/update_status_inventory', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.ngxLoader.stop();
        this.toastr.successToastr(data.replyMsg, 'Success')
      } else {
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onSellerInventoryCreate(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/create_inventory', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.ngxLoader.stop();
        this.toastr.successToastr(data.replyMsg, 'Success')
      } else {
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onSellerInventoryUpdate(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/update_inventory', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.ngxLoader.stop();
        this.toastr.successToastr(data.replyMsg, 'Success')
      } else {
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onUpdateSeller(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/staff_inventory/update_seller', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.ngxLoader.stop();
        this.toastr.successToastr(data.replyMsg, 'Success')
      } else {
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  onLatestNews(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/get_latest_updates',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.manageDataService.SetLatestUpdates(res.data);
        this.ngxLoader.stop()
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }
  onUpcommingList(payload:any){
    this.ngxLoader.start();
    this.baseService.post('v1/get_upcoming_updates',payload).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.manageDataService.SetUpcommingData(res.data);
        this.ngxLoader.stop()
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
      this.ngxLoader.stop();
    })
  }


}

import * as RedirectionPath from '../../common/redirect';

import { ActivatedRoute, Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AmountPatternValidator } from 'src/app/services/amount-pattern-validator';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {Sort} from '@angular/material/sort';
import { ToastrManager } from 'ng6-toastr-notifications';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';

declare var $:any;
@Component({
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.scss'],
  providers:[DatePipe,NgxUiLoaderService]
})
export class AccountBalanceComponent implements OnInit{
public environment = environment;

passengerfilter:boolean=false;
fromdate: any = "";  todate: any = "";  fromdepdate: any = ""; todepdate:any = "";
searchreportbool:boolean=false; airlinecode: any = ""; paymentStatus:any="";
pid:any; paymant_status:any;
SelectedDate:any;
sort_narration: any = "desc";
sort_amount: any = "desc";
sort_account_type: any = "desc";
date:any; isAutoChange: any;
data: any;   isAuto:boolean=false;   todaydate:any = new Date();
bodyRequestData: any = {};
@ViewChild("fileInput1", {static: false}) fileInput1: ElementRef;files = [];
@ViewChild("inputSearch", {static: false}) inputSearch: ElementRef;
ccData:any;
newData:any;
compDtlBlnc:any;
compDtl2:any=[];
compData:any;
compDtl:any=[];
sortedList: any[];
page1: number = 1;
old_credit_limit:any;
ngxLoader:any;
creditCount:any
detail_data:any;
submitted:boolean=false;
searchFlagReset:boolean=false; bodydata:any={};
TotalCount:any; keys:any;public page: number = 1;start = 0;msg:any;status:any;FormId:any;flag:boolean=false;
accountData:any;
account:any;
crediBalance:any='';
creditVal: any=3;
currentDate:any
reversData:any;
myReverseAmount:any;
TotalCount1:any;
  public addaccountValue : FormGroup;
  public addreversal: FormGroup;
  element:any = {};
  userdata:any;
  acountPartner:any;
  old_available_balance:any;
  p: number = 1;
  diffTime:any;
  bankid_id: any;
  reference_id: any='';
  bank_list_data:any=[];
  add_b_sel_op:any=[
    // { title: "Transaction Type", value: 3 },
    // { title: "Give Credit", value: 0 },
    // { title: "Add Balance", value: 1 },
    { title: "Paid To User", value: 'paid_to_user' },
    { title: "Balance", value: 'balance' },
    { title: "Credit", value: 'credit' },
    { title: "Credit Note", value: 'credit_note' },
    { title: "Refund", value: 'refund' },
    { title: "SSR ", value: 'ssr' },
    { title: "Add Pax", value: 'add_pax' },
    { title: "Other Charges", value: 'other_charges' },
  ]
  onSelectDate:any;
  booking_id:any;
  totalpage: any;
  data1: any;
  accDetail: any;

  constructor(
    private fb: FormBuilder,
    public sharedService: SharedserviceService,
    public router: Router,
    private datePipe: DatePipe,
    private toastr: ToastrManager,
    private activatedRoute:ActivatedRoute,
    public dataservice:CommonService,public ngxloader:NgxUiLoaderService,
    private dateAdapter: DateAdapter<Date>,
  ) {
    this.dateAdapter.setLocale('en-GB');
    this.userdata=JSON.parse(localStorage.getItem("user"));
    this.sortedList=this.ccData;
    if(sessionStorage.getItem("account_detail_data")){
      this.detail_data=JSON.parse(sessionStorage.getItem("account_detail_data"))
    }
  }
  ngOnInit() {
    this.bodyRequestData['page1'] = 1;
    this.currentDate = this.todaydate ;
    this.currentDate.toDateString();
    this.detail_data;
    if(this.userdata.role_id == 1){
      this.addaccountValue = this.fb.group({
          share_inventorie_id:[this.detail_data.share_inventorie_id, Validators.required],
          share_to_user_id:[this.detail_data.share_to_user_id, Validators.required],
          amount:['', Validators.required],
          narration:['', Validators.required],
      })
      this.addreversal = this.fb.group({
        patner_vendor_id: ['', Validators.required],
        credit_reversal_for: ['', Validators.required],
        narration: ['', Validators.required],
      })
      this.bodydata={
        share_inventorie_id:this.detail_data.share_inventorie_id,
        share_to_user_id:this.detail_data.share_to_user_id,
        page_limit: "10",
        page_no: "1",
        search_value: "",
        order_key: "",
        order_value: ""
      }
      this.bodydata['page_no']=1;
      this.accountDtl(this.element);
    }
    else if(this.userdata.role_id == 3 || this.userdata.role_id == 5 || this.userdata.b2b_view == 1){
      this.addaccountValue = this.fb.group({
        patner_vendor_id:['', Validators.required],
        amount:['', Validators.required],
        narration:['', Validators.required],
      })
      this.addreversal = this.fb.group({
        patner_vendor_id:['', Validators.required],
        credit_reversal_for: ['', Validators.required],
        narration: ['', Validators.required],
      })
      this.element={
        patner_vendor_id:this.detail_data.patner_vendor_id,
        company_name:this.detail_data.company_name,
        available_balance:this.detail_data.amount == null ? 0 : this.detail_data.amount,
        page_limit: "10",
        page_no: "1",
        search_value: "",
        order_key: "",
        order_value: "", airline_code:"",
        from_book_date:"",  to_book_date:"",  from_dep_date:"",  to_dep_date:"", payment_status:""
      }
      this.bodydata['page_no']=1;
      this.accountDtl(this.element);
    }
    // this.accountShow();
    this.bodyRequestData ={
      patner_vendor_id: this.detail_data.patner_vendor_id,
      company_name: this.detail_data.company_name,
      available_balance: this.detail_data.amount,
      page_limit: "10",
      page_no:1,
      search_value: "",
      order_key: "",
      order_value: "",
    }
  }

  convertToTitleCase(str: string): string {
    return str
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/\b\w/g, (match) => match.toUpperCase()); // Capitalize first letter of each word
  }
  onOptionsSelected(value:string){
  }

  numberOnly(event): boolean {
    //
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }



  onPageChangeReve(event) {
    this.bodyRequestData['page_no'] = event;
    this.creditRevrsList();
  }

  creditRevrsList(){
     this.sharedService.post('v1/vendor_account/credit_not_reversed_list',this.bodyRequestData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        // this.ccData = res.data;
        if ((res.data).length > 0) {
          this.reversData = res.data;
          this.keys = Object.keys(this.reversData);
          this.TotalCount1 = res.totalRecords
          $('#reverseList').modal('show');
        } else {
          this.toastr.errorToastr("Result Not Found", "Error")
        }
      } else {
        this.toastr.errorToastr("Result Not Found", "Error")
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    });

  }

  //*********shorting Start********** /
   sortData(sort: Sort) {
    const data = this.ccData;
    if (!sort.active || sort.direction === '') {
      this.sortedList = data;
      return;
    }
    this.sortedList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'narration': return compare(a.narration, b.narration, isAsc);
        case 'amount': return compare(a.amount, b.amount, isAsc);
        case 'account_type': return compare(a.account_type, b.account_type, isAsc);
        // case 'website': return compare(a.website, b.website, isAsc);
        default: return 0;
      }
    });
    function compare(a: number | string, b: number | string, isAsc: boolean) {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  myFunctionDate(val){
    var newdate=new Date();
    var diff = val.getTime() - newdate.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    this.diffTime= days+" Days, "+ hours+" Hours,"+minutes+" Minute, "+seconds+" Seconds";
    this.onSelectDate = val ;
    this.onSelectDate.toDateString() ;
  }

  ReverseCredit(data){
    var self = this;
    this.old_credit_limit = data.amount
    this.old_available_balance = data.available_balance
    var request_data;
    if(this.userdata.role_id == 1){
      request_data={
        share_inventorie_id: this.detail_data.share_inventorie_id,
        share_to_user_id: this.detail_data.share_to_user_id,
        share_by_user_id: this.detail_data.share_by_user_id,
        credit_reversal_for:(data.available_balance-data.actual_amount),
        narration:"reversed",
      }
    }
    else if(this.userdata.role_id == 3 || this.userdata.role_id == 5 || this.userdata.b2b_cr == 1){
      request_data={
        patner_vendor_id:this.detail_data.patner_vendor_id.toString(),
        credit_reversal_for:(data.id),
        narration:"reversed",
      }
    }
    self.sharedService.post('v1/account/reverse_credit_new', request_data).subscribe((res: any) => {
      if (res.replyCode == 'success') {
        this.creditRevrsList();
        self.toastr.successToastr(res.replyMsg, 'Success');
        this.accountDtl(this.element);
      }
    }, err => {
      self.submitted = false;
      this.creditRevrsList();
      self.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }

  accountShow1(data) {
    this.myReverseAmount = (data.amount)
    // this.myReverseAmount = (data.available_balance-data.actual_amount)

    var sessiondata=JSON.parse(sessionStorage.getItem("account_detail_data"))
    this.old_credit_limit = data.amount
    this.old_available_balance = data.available_balance


      if(this.userdata.role_id == 1){
        this.addreversal.patchValue({
          share_inventorie_id:sessiondata.share_inventorie_id.toString(),
          share_to_user_id:sessiondata.share_to_user_id.toString(),
          credit_reversal_for:(data.amount),
          // credit_reversal_for:(data.available_balance-data.actual_amount),
          narration:"reversed",
        })
      }
      else if(this.userdata.role_id == 3 || this.userdata.role_id == 5 || this.userdata.b2b_view == 1){
        this.addreversal.patchValue({
          patner_vendor_id:sessiondata.patner_vendor_id.toString(),
          credit_reversal_for:(data.id),
          narration:"reversed",
        })
      }

      $('#addReversal').modal('show');
  }

  deleteLastAccTxn(data:any){
    this.accDetail = data;
    $('#deleteAccTxn').modal('show');
  }

  onDeleteAccRecord(){
    var requestData = {
      id:this.accDetail.id,
      user_id: this.detail_data.partner_user_id
    }
    this.ngxloader.start();
    this.sharedService.post('v1/vendor_account/delete', requestData).subscribe((res: any) => {
    if (res.replyCode == 'success') {
      this.ngxloader.stop();
      $('#deleteAccTxn').modal('hide');
      this.toastr.successToastr(res.replyMsg, 'Success');
      this.accountDtl(this.element);
    }
    }, err => {
      this.ngxloader.stop();
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }

  clAccountBal(){
    $('#deleteAccTxn').modal('hide');
  }


  accountDtl(element){
    const self=this;
    this.ngxloader.start()
    if(this.userdata.role_id == 1){
      this.sharedService.post('v1/account/company_account_details', element).subscribe((res: any) => {
        if (res.replyCode == "success" ) {
          this.ccData = res.data;
          this.compDtl= res.company_details;
          this.compDtlBlnc= res.available_balance;
          this.creditCount=res.credit_count;
          this.ngxloader.stop();
          if((res.data).length>0){
              this.data = res.data;
              this.keys = Object.keys(this.data[0]);
              this.TotalCount = res.totalRecords
          this.ngxloader.stop()
            }else{
              this.ngxloader.stop()
              this.msg ='Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            }
        }else{
          this.ngxloader.stop()
          self.toastr.errorToastr("Result Not Found","Error")
        }
      },err=>{
        this.ngxloader.stop()
        this.toastr.errorToastr(err.error.replyMsg,"Error");
      });
    }
    else {
      this.ngxloader.start()
        this.sharedService.post('v1/vendor_account/company_account_details_new', element).subscribe((res: any) => {
        if (res.replyCode == "success" ) {
          this.ccData = res.data;
          this.compDtl2= res.company_details;
          this.creditCount=res.credit_count;
          this.compDtlBlnc= res.available_balance;
          this.TotalCount = res.totalRecords;
          this.ngxloader.stop();
          if((res.data).length>0){
              this.data = res.data;
              this.newData = this.data[0];
              this.keys = Object.keys(this.data[0]);
              this.TotalCount = res.totalRecords;
              this.ngxloader.stop();
            }else{
              this.ngxloader.stop();
              this.msg ='Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
            }
        }else{
          this.ngxloader.stop();
          self.toastr.errorToastr("Result Not Found","Error")
        }
     },err=>{
        this.ngxloader.stop();
        this.toastr.errorToastr(err.error.replyMsg,"Error");
     });
    }
}
get ab (){return this.addaccountValue.controls}
get rb() { return this.addreversal.controls }

isAutoReverse(event){
  var myvalisAuto = event;
  // this.isAuto = ! this.isAuto;
  if (event.checked == true) {
    this.isAuto = true;
    // localStorage.setItem('ContactShow','true')
  } else {
    this.isAuto = false;
    // localStorage.setItem('ContactShow','false')
  }
}

accountShow(){
  this.bankList();
    var data=JSON.parse(sessionStorage.getItem("account_detail_data"))
    $('#addBalance').modal('show');
    this.old_credit_limit=data.amount != null?data.amount.toString():''
    this.old_available_balance=data.amount != null?data.amount.toString():''
    if(this.userdata.role_id == 1){
      this.addaccountValue.patchValue({
        share_inventorie_id:data.share_inventorie_id.toString(),
        share_to_user_id:data.share_to_user_id.toString()
      })
    }
    else if(this.userdata.role_id == 3 || this.userdata.role_id == 5 || this.userdata.b2b_view == 1){
      this.addaccountValue.patchValue({
        patner_vendor_id:data.patner_vendor_id.toString(),
        // share_to_user_id:data.share_to_user_id.toString()
      })
    }
  }
  clAccount(){
    $('#addBalance').modal('hide');
    this.submitted=false;
  }
  onChangeAccountType(type:any){
    // console.log("this.addaccountPartner",type,this.addaccountValue)
    if(type == 0){
      this.addaccountValue.get('amount').setValidators([Validators.required,AmountPatternValidator(/^[0-9]*(\.[0-9]{0,8})?$/)]);
      this.addaccountValue.controls['amount'].updateValueAndValidity();
    }else{
      this.addaccountValue.get('amount').clearValidators();
      this.addaccountValue.controls['amount'].updateValueAndValidity();
      this.addaccountValue.get('amount').setValidators([Validators.required]);
      this.addaccountValue.controls['amount'].updateValueAndValidity();
    }
  }
  addAccount(){
    var self = this;
    self.submitted = true;
    if (self.addaccountValue.invalid) {
      return;
    } else {
      // if(this.submitted = true && this.crediBalance == '' && this.creditVal == 3){
      //   return;
      if((this.creditVal=='credit_note' && this.reference_id == '')||this.creditVal=='ssr' && this.reference_id == ''||this.creditVal=='add_pax' && this.reference_id == ''){
        return;
      }else{
        var data = self.addaccountValue.value;
        if(this.userdata.role_id == 1){
          var request_data:any={
            share_inventorie_id: Number(data.share_inventorie_id),
            share_to_user_id: Number(data.share_to_user_id),
            amount:data.amount ,
            narration: data.narration,
            reference_id: this.reference_id,
            account_type: this.creditVal,
            // account_type: this.creditVal == 0?'credit':'balance',  // 'balance': to add balance, 'credit': to add credit
            // credit_reversal_date:this.crediBalance ==''?'':this.datePipe.transform(this.crediBalance, 'yyyy-MM-dd'),   // cannot be null if account_type = 'credit'
            // is_auto_reverse:this.isAuto,
            // bank_id: (this.bankid_id!=''&& this.bankid_id!=null)?this.bankid_id:''
          }
          var addBalanceData = 'v1/account/add_balance_new'
        }
        else if(this.userdata.role_id == 3 || this.userdata.role_id == 5 || this.userdata.b2b_bal == 1){
          var request_data:any={
            user_id: Number(data.patner_vendor_id),
            amount:data.amount ,
            narration: data.narration,
            account_type: this.creditVal,
            reference_id: this.reference_id,
            // account_type: this.creditVal == 0?'credit':'balance',  // 'balance': to add balance, 'credit': to add credit
            // credit_reversal_date:this.crediBalance ==''?'':this.datePipe.transform(this.crediBalance, 'yyyy-MM-dd'),   // cannot be null if account_type = 'credit'
            // is_auto_reverse:this.isAuto
          }
          var addBalanceData = 'v1/vendor_account/add_balance_new'
        }
        this.ngxloader.start();
        self.sharedService.postRequest(addBalanceData,request_data).subscribe((res: any) => {
          if (!res.error) {
            this.ngxloader.stop();
            if(res.replyCode == 'success') {
              this.accountData = res.data;
              this.crediBalance="";
              this.creditVal=3;
              self.submitted = false;
              $('#addBalance').modal('hide');
              this.accountDtl(this.element);
              var cl= Number(this.detail_data.amount)+Number(request_data.amount)
              if(this.userdata.role_id == 1){
                var data={
                  share_inventorie_id:this.detail_data.share_inventorie_id.toString(),
                  share_to_user_id:this.detail_data.share_to_user_id.toString(),
                  amount:cl.toString()
                }
                sessionStorage.setItem("account_detail_data",JSON.stringify(data))
                // this.accountDtl(this.detail_data)
              }
              else if(this.userdata.role_id == 3 || this.userdata.role_id == 5){
                var data2={
                  patner_vendor_id:this.detail_data.patner_vendor_id.toString(),
                  amount:cl.toString()
                }
                this.crediBalance="";
                this.creditVal=3;
                sessionStorage.setItem("account_detail_data",JSON.stringify(data2))
                // this.accountDtl(this.element)
              }
              self.addaccountValue.reset();
              $('#addBalance').modal('hide');
              // this.accountDtl(this.element)
              self.submitted = false;
              self.toastr.successToastr(res.replyMsg, 'Success');
            }else {
              this.ngxloader.stop();
              self.submitted = false;
              this.crediBalance="";
              this.creditVal=3;
              self.addaccountValue.reset();
              $('#addBalance').modal('hide');
              self.toastr.errorToastr(res.replyMsg, 'Error');
            }
          }else {
            this.ngxloader.stop();
            self.submitted =false;
            this.crediBalance="";
            this.creditVal=3;
            self.addaccountValue.reset();
            $('#addBalance').modal('hide');
            self.toastr.errorToastr(res.error.replyMsg, 'Error');
          }
        },error => {
          this.ngxloader.stop();
            self.submitted =false;
            this.crediBalance="";
            this.creditVal=3;
            self.addaccountValue.reset();
            $('#addBalance').modal('hide');
            self.toastr.errorToastr(error.error.replyMsg);
        });
      }

    }
  }

  bankList(){
    var self = this;
    var request = {
      user_id: this.userdata.id
     }
     self.sharedService.post('v1/bank/list',request).subscribe((res:any) =>{
      if (res.replyCode == "success") {
        this.bank_list_data = res.data.rows;
      }
     },err=> {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    })
  }

  onSelectBank(value){
    this.bankid_id = value;
  }

  boxAddReversal(data) {
    var self = this;
    self.submitted = true;
    var request_data = {
      patner_vendor_id:this.detail_data.patner_vendor_id.toString(),
      credit_reversal_for:(data.id),
      narration:"reversed",
    };
    if (this.userdata.role_id == 3 || this.userdata.role_id == 5 || this.userdata.b2b_cr == 1) {
      var addBalanceData = 'v1/vendor_account/reverse_credit_new'
    }
    self.sharedService.postRequest(addBalanceData, request_data).subscribe((res: any) => {
        if (res.replyCode == 'success') {
          this.accountData = res.data;
          self.submitted = false;
          $('#reverseList').modal('hide');
          self.addreversal.reset();
          this.accountDtl(this.element);
          setTimeout(() => {
            self.addreversal.patchValue({
              share_inventorie_id: self.detail_data.share_inventorie_id,
              share_to_user_id: self.detail_data.share_to_user_id
            })
            self.toastr.successToastr(res.replyMsg, 'Success');
          }, 500);
        }
    }, err => {
      self.submitted = false;
      $('#reverseList').modal('hide');
      self.addreversal.reset();
      setTimeout(() => {
        self.addreversal.patchValue({
          share_inventorie_id: self.detail_data.share_inventorie_id,
          share_to_user_id: self.detail_data.share_to_user_id
        })
        self.toastr.errorToastr(err.error.replyMsg, 'Error');
      }, 500);
    });
  }

  addReversal() {
    var self = this;
    self.submitted = true;
    if (self.addreversal.invalid) {
      return;
    } else {
        var request_data = self.addreversal.value;
        if (this.userdata.role_id == 3 || this.userdata.role_id == 5) {
          var addBalanceData = 'v1/vendor_account/reverse_credit_new'
        }
        self.sharedService.postRequest(addBalanceData, request_data).subscribe((res: any) => {
            if (res.replyCode == 'success') {
              this.accountData = res.data;
              self.submitted = false;
              $('#addReversal').modal('hide');
              self.addreversal.reset();
              this.accountDtl(this.element);
              setTimeout(() => {
                self.addreversal.patchValue({
                  share_inventorie_id: self.detail_data.share_inventorie_id,
                  share_to_user_id: self.detail_data.share_to_user_id
                })
                self.toastr.successToastr(res.replyMsg, 'Success');
              }, 500);
            }
        }, err => {
          self.submitted = false;
          $('#addReversal').modal('hide');
          self.addreversal.reset();
          setTimeout(() => {
            self.addreversal.patchValue({
              share_inventorie_id: self.detail_data.share_inventorie_id,
              share_to_user_id: self.detail_data.share_to_user_id
            })
            self.toastr.errorToastr(err.error.replyMsg, 'Error');
          }, 500);
        });

    }
  }

  SearchAirlineCode(){
    if(this.airlinecode.length == 2){
      this.searchreport()
    }
  }

  onChangePaymentStatus(event: any) {
      this.paymentStatus = Number(event);
      this.onSearch()
  }

  searchreport(){
    if(this.fromdate != '' && this.todate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.fromdepdate != '' && this.todepdate != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else if(this.airlinecode != ''){
      this.searchreportbool=true;
      this.onSearch()
    }else{
      this.searchreportbool=true;
    }
  }

  onSearch(){
    var element;
    if (this.fromdate != '') {
      this.fromdate = this.datePipe.transform(new Date(this.fromdate), 'yyyy-MM-dd');
    } else {
      this.fromdate = ''
    }
    if (this.todate != '') {
      this.todate = this.datePipe.transform(new Date(this.todate), 'yyyy-MM-dd');
    } else {
      this.todate = ''
    }
    if (this.fromdepdate != '') {
      this.fromdepdate = this.datePipe.transform(new Date(this.fromdepdate), 'yyyy-MM-dd');
    } else {
      this.fromdepdate = ''
    }
    if (this.todepdate != '') {
      this.todepdate = this.datePipe.transform(new Date(this.todepdate), 'yyyy-MM-dd');
    } else {
      this.todepdate = ''
    }
    if (this.airlinecode != '') {
      this.airlinecode = this.airlinecode;
    } else {
      this.airlinecode = ''
    }

    this.searchFlagReset = true;
    var requestdata={
      company_name:this.detail_data.company_name,
      available_balance:this.detail_data.amount == null ? 0 : this.detail_data.amount,
      patner_vendor_id:this.detail_data.patner_vendor_id,
      // share_inventorie_id:this.detail_data.share_inventorie_id,
      // share_to_user_id:this.detail_data.share_to_user_id,
      page_limit: this.TotalCount,
      page_no: "",
      // search_value:  String(this.inputSearch.nativeElement.value),
      search_value: '',
      order_key: "",
      order_value: "", airline_code:this.airlinecode.toUpperCase(), payment_status:this.paymentStatus, from_book_date:this.fromdate,  to_book_date:this.todate,  from_dep_date:this.fromdepdate,  to_dep_date:this.todepdate,
    }

    this.sharedService.post('v1/vendor_account/company_account_details_new', requestdata).subscribe((res) => {
      if (res.replyCode == "success") {
        this.searchFlagReset = true;
        // this.ccData = res.data;
        this.data = res.data;
        this.totalpage = (res.count)/(requestdata.page_limit);  this.TotalCount = res.totalRecords;
      }
    }, err => {
    })
    // this.accountDtl(requestdata);
  }
  onReset(){
    // this.inputSearch.nativeElement.value='';
    this.inputSearch=null;
    this.searchFlagReset = false;
    this.element['search'] = '';
    this.fromdate = '';
    this.todate = '';
    this.fromdepdate = '';
    this.todepdate = '';
    this.airlinecode = '';
    this.paymentStatus = '';
    this.accountDtl(this.element);
  }
  onPageChange(event) {
    var requestdata = {
      patner_vendor_id:this.detail_data.patner_vendor_id,
      company_name:this.detail_data.company_name,
      available_balance:this.detail_data.amount == null ? 0 : this.detail_data.amount,
      page_limit: 10,
      page_no: event,
      search: "",
      order_key: "",
      order_value: "", airline_code:this.airlinecode,
      from_book_date:this.fromdate,  to_book_date:this.todate,  from_dep_date:"",  to_dep_date:"", payment_status:""
    }
    this.accountDtl(requestdata);
  }

  sortfun(orderkey, type) {
    if (orderkey == 'narration' && type == 'asc') {
      this.sort_narration = 'asc';
    } else {
      this.sort_narration = 'desc';
    }
    if (orderkey == 'amount' && type == 'asc') {
      this.sort_amount = 'asc';
    } else {
      this.sort_amount = 'desc';
    }
    if (orderkey == 'account_type' && type == 'asc') {
      this.sort_account_type = 'asc';
    } else {
      this.sort_account_type = 'desc';
    }

    this.searchFlagReset = true;
    var DataSearch = {
      patner_vendor_id:this.detail_data.patner_vendor_id,
      company_name:this.detail_data.company_name,
      available_balance:this.detail_data.amount == null ? 0 : this.detail_data.amount,
      page_limit: 10,
      page_no: "",
      search: "",
      //search: String(this.inputSearch.nativeElement.value) ? String(this.inputSearch.nativeElement.value) : "",
      order_key: orderkey,
      order_value: type
    }

    this.accountDtl(DataSearch);
  }

  resetAccount(){
    var data ={
      user_id: Number(this.detail_data.patner_vendor_id)
    }
    this.sharedService.post('v1/vendor_account/reset', data).subscribe((res: any) => {
        if (res.status == 'success') {
          this.accountData = res.data;
          this.toastr.successToastr(res.status, 'Success');
          this.accountDtl(this.element)
          this.router.navigate(['/dashboard/account-balance'])
          .then(() => {
            window.location.reload();
          });
        }
      }, err => {
        this.toastr.errorToastr(err.error.status, 'Error');
    });
  }

  viewDetail(element){
    this.booking_id = element.booking_id
    sessionStorage.setItem("booking_id", JSON.stringify(this.booking_id));
    window.open(RedirectionPath.processUrl('booking-summary'), '_blank');
  }

  download(data) {
    var requestdata = {
      company_name:this.detail_data.company_name,
      available_balance:this.detail_data.amount == null ? 0 : this.detail_data.amount,
      patner_vendor_id:this.detail_data.patner_vendor_id,
      page_limit: this.TotalCount,
      page_no: "1",
      search_value: '',
      order_key: "",
      order_value: "", airline_code:this.airlinecode.toUpperCase(), payment_status:this.paymentStatus, from_book_date:this.fromdate,  to_book_date:this.todate,  from_dep_date:this.fromdepdate,  to_dep_date:this.todepdate,

    }
    const self = this;
      this.sharedService.post('v1/vendor_account/company_account_details_new', requestdata).subscribe((res: any) => {
        if (res.replyCode == "success") {
          if ((res.data).length > 0) {
            this.data1 = res.data;
            setTimeout(() => {
              this.exportdata();
            }, 1300);
          } else {
            this.msg = 'Sorry for the inconvenience caused. Please contact at 9352851051 for customer support.'
          }
        } else {
          self.toastr.errorToastr("Result Not Found", "Error")
        }
      }, err => {
        this.toastr.errorToastr(err.error.replyMsg, "Error");
      });
  }

  exportdata(): void {
    /* table id is passed over here */
    let element = document.getElementById('contentToConvert');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Excel-AgencyReport.xlsx');

  }

  onChangePaymentStatusModal(id,payStatus){
    this.pid = id;
    this.paymant_status = payStatus;

    var requesData = {
      "id":this.pid,
      "status":this.paymant_status
    }

    this.sharedService.post('v1/account/mark_received', requesData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.accountDtl(this.element);
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr("Result Not Found", "Error")
      }
    }, err => {
      this.toastr.errorToastr(err.error.replyMsg, "Error");
    });

  }

  onRightClick(id,payStatus) {
    this.onChangePaymentStatusModal(id,payStatus);
    return false;
  }

  filterwithpass(){
    if(this.passengerfilter == false ){
      this.passengerfilter=true;
      this.onSearch()
    }else{
      this.passengerfilter=false;
      this.onSearch()
    }
  }
}
